<template>
  <div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Agent
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Service
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Amount
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Description
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Date / Time
          </th>

          <th scope="col" class="relative px-6 py-3">
            <span class="sr-only"></span>
          </th>
        </tr>
      </thead>
      <tbody v-if="deposits" class="bg-white divide-y divide-gray-200">
        <tr v-for="(deposit, i) in deposits.data" :key="i">
          <td class="px-3 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ deposit.agent_id }} {{ deposit.agent_name }}
                </div>
              </div>
            </div>
          </td>

          <td class="px-3 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ deposit.account }}
                </div>
              </div>
            </div>
          </td>

          <td class="px-3 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  GMD {{ deposit.amount }}
                </div>
              </div>
            </div>
          </td>

          <td class="px-3 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ deposit.description }}
                </div>
              </div>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            {{ deposit.created_at }}
          </td>

          <td
            class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
          >
            <a href="#" class="text-indigo-600 hover:text-indigo-900">View</a>
          </td>
        </tr>

        <!-- More people... -->
      </tbody>
    </table>

    <div class="border-t" v-if="deposits">
      <pagination :data="deposits" :midSize="2" @clicked="loadData" />
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data: () => ({
    deposits: null,
    filter: {
      agentname: "",
      type: "",
      fromdate: "",
      todate: "",
      depositMethod: "",
    },
  }),

  created() {
    var _this = this;
    _this.loadData(1);
  },

  methods: {
    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/tx/deposits?page=${pageNo}&agentname=${filter.agentname}&type=${filter.type}&fromdate=${filter.fromdate}&todate=${filter.todate}`
        );

        this.deposits = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },
  },
};
</script>